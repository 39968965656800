// Add helpers plugins here
import { mergeDeepObjects } from '@/plugins/helpers'

// Add localStorage keys here
const stateKeys = {
  currentSolution: 'currentSolution',
}

// Add Vuex Store keys here
export const state = () => ({
  overrides: {},
  solutions: {},
  currentSolution: null,
  localCurrentSolution: null,
  calculations: {},
  isSilentLoading: true,
  isLoading: true,
  initialData: null,
})

export const mutations = {
  clearStorage(state) {
    for (const [key] of Object.entries(state)) {
      state[`${key}`] = state[key]
    }
  },
  setSolutions(state, value) {
    state.solutions = value
  },
  setCurrentSolution(state, value) {
    state.currentSolution = value
  },
  setLocalCurrentSolution(state, value) {
    state.localCurrentSolution = value
  },
  setOverrides(state, value) {
    state.overrides = value
  },
  setCalculations(state, value) {
    state.calculations = value
  },
  setIsSilentLoading(state, value) {
    state.isSilentLoading = value
  },
  setIsLoading(state, value) {
    state.isLoading = value
  },
}

export const actions = {
  clearStorage({ commit }) {
    for (const prop in stateKeys) {
      this._vm?.$storage?.removeUniversal(stateKeys[prop])
    }
    commit('clearStorage')
  },
  async getSolutions({ state, commit }, queryProps) {
    commit('setIsLoading', true)
    const apiResult = await this.$apiAaltra.getSolutions(
      queryProps?.country,
      queryProps?.language,
      queryProps?.tbsId
    )

    if (apiResult) {
      await commit(
        'setSolutions',
        mergeDeepObjects(false, state.solutions, apiResult)
      )
      commit('setIsLoading', false)
    } else {
      commit('setIsLoading', false)
    }
  },
  async updateCurrentSolution({ state, rootState, commit }, value) {
    const hasUpdatedProjectSize = !!value?.size
    if (value) {
      commit('setIsLoading', hasUpdatedProjectSize ? 'project-size' : true)
    }

    let currentSolution
    const currentSize =
      state.solutions.applications[0]?.totalBuildingSolutions.find(
        (tbs) => tbs.type === 'daikin'
      )?.size

    if (!value) {
      const currentApplication = state.solutions.applications[0]
      let sizeCapacity =
        currentApplication?.units?.peoplePerUnit *
        currentApplication?.sizes[currentSize]?.from
      let sizeSurface =
        currentApplication?.units?.surfacePerUnit *
        currentApplication?.sizes[currentSize]?.from
      let sizeNumberOfRooms = currentApplication?.sizes[currentSize]?.from

      const unitOfMeasure = currentApplication?.units?.unitOfMeasure

      if (unitOfMeasure === 'm2') {
        sizeSurface = currentApplication?.sizes[currentSize]?.defaultValue
        sizeCapacity = sizeSurface * currentApplication?.units?.peoplePerUnit
        sizeNumberOfRooms = sizeSurface
      } else {
        sizeNumberOfRooms = currentApplication?.sizes[currentSize]?.defaultValue
        sizeSurface =
          sizeNumberOfRooms * currentApplication?.units?.surfacePerUnit
        sizeCapacity = Math.ceil(
          sizeNumberOfRooms * currentApplication?.units?.peoplePerUnit
        )
      }

      currentSolution = {
        id: state.solutions.applications[0]?.id,
        name: state.solutions.applications[0]?.name,
        image: state.solutions.applications[0]?.image,
        partner:
          state.solutions.partner?.emailAddress?.length > 0
            ? state.solutions.partner
            : null,
        energySources: state.solutions.energySources,
        totalBuildingSolutions: {
          // Applications is always 1 length
          vendorSolutionId:
            state.solutions.applications[0]?.totalBuildingSolutions.find(
              (tbs) => tbs.type === 'daikin'
            )?.id,
          benchmarkSolutionId:
            state.solutions.applications[0]?.totalBuildingSolutions.find(
              (tbs) => tbs.type === 'benchmark'
            )?.id,
        },
        country: {
          climateZone: state.solutions.climateZones[0],
          country: rootState.postal.country,
          language: rootState.postal.language,
          emissionsTaxPerTonne:
            state.currentSolution?.country?.emissionsTaxPerTonne || 0,
        },
        size: {
          name: currentSize,
          capacity: sizeCapacity < 1 ? 1 : sizeCapacity,
          surface: sizeSurface < 1 ? 1 : sizeSurface,
          numberOfRooms: sizeNumberOfRooms < 1 ? 1 : sizeNumberOfRooms,
        },
        // Default to the last insulation level.
        insulationLevel: Object.keys(state.solutions.insulationLevels).slice(
          -1
        )[0],
        sizes: state.solutions.applications[0]?.sizes,
        units: state.solutions.applications[0]?.units,
        crrem: {
          pathway: 'decarbonizationPathway',
          warmingTarget: state.solutions.applications[0]?.crrem[0]?.warming,
        },
      }
    } else {
      currentSolution = {
        ...state.currentSolution,
        ...value,
      }
    }

    const oldCurrentSolution = { ...state.currentSolution }
    await commit(
      'setCurrentSolution',
      mergeDeepObjects(false, oldCurrentSolution, currentSolution)
    )
    await setTimeout(
      async () => {
        await commit('setIsLoading', false)
      },
      rootState?.postal?.reportId ? 0 : 800
    )
  },
  async resetOverrides({ state, commit }) {
    const currentSolution = { ...state.currentSolution }
    delete currentSolution.overrides
    await commit('setCurrentSolution', currentSolution)
  },
  getLocalCurrentSolution() {
    return this._vm?.$storage?.getUniversal(stateKeys.currentSolution)
  },
  setLocalCurrentSolution({ commit }, value) {
    commit('setLocalCurrentSolution', value)
    this._vm?.$storage?.setUniversal(stateKeys.currentSolution, value)
  },
}
